import './App.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the grid
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import NewRound from './NewRound';
import Category from './Category';
import { useEffect, useState } from 'react';
import { AppContext } from './AppContext';
import {
  Category as CategoryType,
  Round_Groups,
  Group,
  Match,
  Player,
  Score,
  Tourney,
} from './types';
import Matches from './Matches';
import useLoadAllData from './useLoadAllData';
import { Pane, Spinner } from 'evergreen-ui';
import TournamentSchedule from './TournamentSchedule';
import Home1 from './Home1';
import AdminButton from './AdminButton';
import NewRoundWithSamePlayers from './NewRoundWithSamePlayers';

function App() {
  const [tourneys, setTourneys] = useState<Tourney[]>([]);
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [groups, setGroups] = useState<Group[]>([]);
  const [players, setPlayers] = useState<Player[]>([]);
  const [round_groups, setRoundGroups] = useState<Round_Groups[]>([]);
  const [scores, setScores] = useState<Score[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  const { loading, loadAllData } = useLoadAllData();

  useEffect(() => {
    loadAllData();
  }, []);

  if (loading) {
    return (
      <Pane>
        <Spinner marginX='auto' marginY={120} />
      </Pane>
    );
  }

  return (
    <div className='App'>
      <AppContext.Provider
        value={{
          tourneys,
          setTourneys,
          categories,
          setCategories,
          groups,
          setGroups,
          players,
          setPlayers,
          round_groups,
          setRoundGroups,
          scores,
          setScores,
          matches,
          setMatches,
          isAdmin,
          setIsAdmin,
        }}
      >
        {/* <NavBar></NavBar> */}
        <Router>
          <div>
            <Routes>
              <Route path='/newRound/:categoryId' Component={NewRound}></Route>
              <Route path='/schedule' Component={TournamentSchedule}></Route>
              <Route path='/category/:id' Component={Category}></Route>
              <Route path='/matches/:id' Component={Matches}></Route>
              <Route path='/home' Component={Home}></Route>
              <Route path='/' Component={Home1}></Route>
              <Route path='/:user' Component={Home}></Route>
              <Route path='/admin' Component={AdminButton}></Route>
            </Routes>
          </div>
        </Router>
        {/* <Footer /> */}
      </AppContext.Provider>
    </div>
  );
}

export default App;
