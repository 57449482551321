import { useContext, useState } from 'react';
import { AppContext } from './AppContext';
import { Pane, TextInput, Button } from 'evergreen-ui';
import './AdminButton.css';
import { useNavigate } from 'react-router-dom';

const AdminButton = () => {
  const { isAdmin, setIsAdmin } = useContext(AppContext);
  const [password, setPassword] = useState('');
  const [isError, setError] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = () => {
    if (password === 'mcpicklers123') {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
      setError(true);
    }
  };

  return (
    <Pane className='admin-container'>
      {!isAdmin && (
        <>
          <TextInput
            className='admin-password-input'
            name='password'
            placeholder='Entry admin password here'
            value={password}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value);
              if (isError) {
                setError(false);
              }
            }}
            isInvalid={isError}
          />
          {isError && (
            <div style={{ color: 'red', marginTop: '5px' }}>
              Incorrect password
            </div>
          )}
          <Button className='admin-submit-button' onClick={handleSubmit}>
            Login
          </Button>
        </>
      )}
      {isAdmin && (
        <div onClick={() => navigate('/home')} className='admin-password-input'>
          Logged in, back to home
        </div>
      )}
    </Pane>
  );
};

export default AdminButton;
