import { Button, Card } from 'evergreen-ui';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { supabase } from './utils';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from './AppContext';
import Ribbon from './Ribbon';
import useLoadAllData from './useLoadAllData';
import NewCategoryV2 from './NewCategoryV2';
import useStore from './useStore';
import CategorySummary from './CategorySummary';

const Home = () => {
  // dialogs
  const [showCategoryDialog, setShowCategoryDialog] = useState(false);

  const { categories, setCategories, isAdmin } = useContext(AppContext);
  const tourneys = useStore((state) => state.tourneys);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const { user } = useParams();

  const { loadAllData } = useLoadAllData();

  useEffect(() => {
    loadAllData();
  }, []);

  const refreshData = useCallback(() => {
    setLoading(true);
    supabase
      .from('categories')
      .select()
      .then((response) => {
        if (response.data) {
          setCategories(response.data);
        }
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    refreshData();
  }, []);

  const currentTourney = useMemo(() => tourneys[0], [tourneys]);
  const sortedCategories = categories.sort((a, b) => (a.id > b.id ? -1 : 1));

  useEffect(() => {
    if (sortedCategories.length > 0) {
      setSelectedCategory(`${sortedCategories[0].id}`);
    }
  }, [sortedCategories]);

  return (
    <>
      <div className='home-page-image-container'>
        <img src='/logo.png' height='100px'></img>
      </div>
      {currentTourney && (
        <>
          <Ribbon text={currentTourney.name} showBackButton={false}></Ribbon>
          <NewCategoryV2
            isShown={showCategoryDialog}
            setIsShown={setShowCategoryDialog}
            tourneyId={currentTourney.id}
          />
        </>
      )}

      <div className='tableWrapper'>
        {/* {isAdmin && ( */}
        <div className='actionRow'>
          {
            <>
              <Button
                appearance='primary'
                onClick={() => setShowCategoryDialog(true)}
                // disabled={!isAdmin}
              >
                <b>Add a new category</b>
              </Button>
            </>
          }
        </div>
        {/* // )} */}
        <div className='categories'>
          {sortedCategories.map((category) => {
            return (
              <Card
                key={category.id}
                elevation={2}
                className='categoryBox'
                onClick={() => navigate(`category/${category.id}`)}
              >
                <CategorySummary categoryId={category.id} />
              </Card>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Home;
