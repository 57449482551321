import { PostgrestSingleResponse } from '@supabase/supabase-js';

import { Category, Round, Semi } from './types';
import {
  generateDoublesRoundRobin,
  generateSinglesRoundRobin,
  supabase,
} from './utils';

const buildMatches = async ({
  numberOfGroups,
  numberOfPlayers,
  type,
  roundPlayers,
  playerIds,
  roundName,
  tourney,
  playTill,
  winBy,
  categoryId,
}: {
  numberOfGroups: number;
  numberOfPlayers: number;
  type: string;
  roundPlayers: number[];
  playerIds: number[];
  roundName: string;
  tourney: string;
  playTill: number;
  winBy: number;
  categoryId: number;
}) => {
  console.log('Avi, build matches , playerId', playerIds);

  if (['semis'].includes(type!)) {
    console.log('Avi, categoryId: ', categoryId, playerIds);
    // generate matches for semis and finals
    const match1 = {
      categoryId: categoryId,
      matchIdentifier: 0,
      team1: playerIds[0].toString(),
      team2: playerIds[1].toString(),
      score1: 0,
      score2: 0,
      winner: '',
      loser: '',
      completed: false,
      playTill,
      winBy,
    };

    const match2 = {
      categoryId: categoryId,
      matchIdentifier: 1,
      team1: playerIds[2].toString(),
      team2: playerIds[3].toString(),
      score1: 0,
      score2: 0,
      winner: '',
      loser: '',
      completed: false,
      playTill,
      winBy,
    };

    const semis = [match1, match2];

    const semisResponse = await supabase.from('semis').insert(semis).select();
    console.log('Avi, semisResponse: ', semisResponse);
    return;
  }
  // create a round
  const roundData = {
    name: roundName,
    categoryId: categoryId,
  };

  const roundInfo = await supabase
    .from('rounds')
    .insert([roundData])
    .select()
    .then((response: PostgrestSingleResponse<Round[]>) => {
      if (response.data) {
        return response.data[0];
      }
    });

  // create groups

  const groupData = roundPlayers.map((rp, index) => {
    return {
      name: `Group ${index + 1}`,
      numPlayers: rp,
      playTill,
      winBy,
      roundId: roundInfo?.id,
    };
  });

  const groups = await supabase
    .from('groups')
    .insert(groupData)
    .select()
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });

  const roundGroupsMapping = groups?.map((group) => {
    const playerIdsForGroup = playerIds?.splice(0, group.numPlayers);

    const groups = playerIdsForGroup.map((playerId) => {
      return {
        roundId: roundInfo?.id,
        groupId: group.id,
        playerId,
        categoryId: categoryId,
      };
    });

    return groups.map((g) => g);
  });

  const roundGroupsMappingToSave: {
    roundId: number | undefined;
    groupId: any;
    playerId: any;
    categoryId: number;
  }[] = [];

  roundGroupsMapping?.forEach((v) =>
    v.forEach((v1) => roundGroupsMappingToSave.push(v1))
  );

  const roundGroupsMappingResponse = await supabase
    .from('round_groups')
    .insert(roundGroupsMappingToSave)
    .select()
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });

  // generate matches

  const group_matches: {
    groupId: any;
    team1: string | number;
    team2: string | number;
    bye: string | number | null;
  }[] = [];

  if (['singles', 'teams'].includes(type!)) {
    roundGroupsMapping?.forEach((categoryGroup) => {
      const groupId = categoryGroup[0].groupId;
      const players = categoryGroup.map((g) => g.playerId);
      const matches = generateSinglesRoundRobin(players);
      console.log({ matches });
      matches.forEach((match) => {
        const data = {
          groupId,
          team1: match[0],
          team2: match[1],
          bye: null,
        };
        group_matches.push(data);
      });
    });

    const matchesResponse = await supabase
      .from('matches')
      .insert(group_matches)
      .select();
  } else if (['doubles'].includes(type!)) {
    roundGroupsMapping?.forEach((categoryGroup) => {
      const groupId = categoryGroup[0].groupId;
      const players = categoryGroup.map((g) => g.playerId);
      const matches = generateDoublesRoundRobin(players);
      console.log({ matches });
      matches.forEach((match) => {
        const data = {
          groupId,
          team1: match[0],
          team2: match[1],
          bye: match[2],
        };
        group_matches.push(data);
      });
    });

    const matchesResponse = await supabase
      .from('matches')
      .insert(group_matches)
      .select();

    console.log({ matchesResponse });
  }
};

export default buildMatches;
