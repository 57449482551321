import { useCallback } from 'react';
import useStore from './useStore';
import { supabase } from './utils';

const useBuildFinals = () => {
  const semis = useStore((state) => state.semis);
  const setFinals = useStore((state) => state.setFinals);

  return useCallback(
    async (categoryId: number) => {
      const semisForThisCategory = semis.filter(
        (semi) => semi.categoryId === categoryId
      );

      if (semisForThisCategory.length === 0) {
        return;
      }

      const winners = semisForThisCategory.map((semi) => semi.winner);
      const losers = semisForThisCategory.map((semi) => semi.loser);

      const finalMatch1 = {
        categoryId,
        matchIdentifier: 0,
        team1: winners[0],
        team2: winners[1],
        score1: 0,
        score2: 0,
      };

      const finalMatch2 = {
        categoryId,
        matchIdentifier: 1,
        team1: losers[0],
        team2: losers[1],
        score1: 0,
        score2: 0,
      };
      const finals = [finalMatch1, finalMatch2];

      const finalsResponse = await supabase
        .from('finals')
        .insert(finals)
        .select('*');

      setFinals(finalsResponse.data || []);
      return;
    },

    [semis]
  );
};

export default useBuildFinals;
