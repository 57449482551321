import { useNavigate } from 'react-router-dom';
import { Button } from 'evergreen-ui';

import './Ribbon.css';

const Ribbon = ({
  text,
  showBackButton = true,
}: {
  text: string;
  showBackButton?: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div
      className='ribbon-container'
      onClick={() => {
        console.log('Click');
        navigate(-1);
      }}
    >
      {showBackButton && (
        <div className='ribbon-back-button'>
          <div>{`<`}</div>
        </div>
      )}
      <div className='ribbon-text'>{text}</div>
    </div>
  );
};

export default Ribbon;
