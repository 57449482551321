import CountdownTimer from './CountDown';

const Home1 = () => {
  return (
    <>
      <h2>2024 Mountain Crest Fall Tournament</h2>

      <div
        style={{
          width: '100%',
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <h4>The weekend of October 4, 2024</h4>
        <a href='https://forms.gle/9L1WLqGdAzenJnJC7'>Sign up form</a>
        <h5>Tourneys for every age group</h5>

        <div style={{ width: '100%', marginTop: '20px' }}>
          {/* <CountdownTimer /> */}
        </div>
      </div>
    </>
  );
};

export default Home1;
