import { Dispatch, SetStateAction, createContext } from 'react';
import {
  Category,
  Round_Groups,
  Group,
  Match,
  Player,
  Score,
  Tourney,
} from './types';

type AppContextType = {
  tourneys: Tourney[];
  setTourneys: Dispatch<SetStateAction<Tourney[]>>;
  categories: Category[];
  setCategories: Dispatch<SetStateAction<Category[]>>;
  groups: Group[];
  setGroups: Dispatch<SetStateAction<Group[]>>;
  players: Player[];
  setPlayers: Dispatch<SetStateAction<Player[]>>;
  matches: Match[];
  setMatches: Dispatch<SetStateAction<Match[]>>;
  round_groups: Round_Groups[];
  setRoundGroups: Dispatch<SetStateAction<Round_Groups[]>>;
  scores: Score[];
  setScores: Dispatch<SetStateAction<Score[]>>;
  isAdmin: boolean;
  setIsAdmin: Dispatch<SetStateAction<boolean>>;
};
export const AppContext = createContext<AppContextType>({
  tourneys: [],
  setTourneys: () => {},
  categories: [],
  setCategories: () => {},
  groups: [],
  setGroups: () => {},
  players: [],
  setPlayers: () => {},
  round_groups: [],
  setRoundGroups: () => {},
  scores: [],
  setScores: () => {},
  matches: [],
  setMatches: () => {},
  isAdmin: false,
  setIsAdmin: () => {},
});
