import useStore from './useStore';

const Winners = ({ categoryId }: { categoryId: number }) => {
  const finals = useStore((state) => state.finals);
  const players = useStore((state) => state.players);

  const finalsForCategory = finals
    .filter((f) => f.categoryId === categoryId)
    .sort((a, b) => a.id - b.id);

  if (finalsForCategory.length === 0) {
    return null;
  }
  const bothFinalsCompleted = finalsForCategory.every((f) => f.completed);
  if (!bothFinalsCompleted) {
    return null;
  }

  const goldSilverMatch = finals.find((f) => f.matchIdentifier === 0);
  const bronzeMatch = finals.find((f) => f.matchIdentifier === 1);

  const goldWinner = players.find(
    (p) => p.id === Number(goldSilverMatch!.winner)
  )!.name;

  const silverWinner = players.find(
    (p) => p.id === Number(goldSilverMatch!.loser)
  )!.name;

  const bronzeWinner = players.find(
    (p) => p.id === Number(bronzeMatch!.winner)
  )!.name;

  return (
    <div>
      <h3>Winners</h3>
      <ul>
        <li>Gold: {goldWinner}</li>
        <li>Silver: {silverWinner}</li>
        <li>Bronze: {bronzeWinner} </li>
      </ul>
    </div>
  );
};

export default Winners;
