import { Dialog, TextInput, TextInputField } from 'evergreen-ui';
import { ChangeEventHandler, Dispatch, SetStateAction, useState } from 'react';
import { PostgrestSingleResponse } from '@supabase/supabase-js';
import './NewCategoryV2.css';
import { supabase } from './utils';
import { Category } from './types';

const NewCategoryV2 = ({
  isShown,
  setIsShown,
  tourneyId,
}: {
  isShown: boolean;
  setIsShown: Dispatch<SetStateAction<boolean>>;
  tourneyId: number;
}) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const categoryData = {
    name,
    type,
    date,
    time,
    tourneyId,
  };

  const handleSubmit = async () => {
    setLoading(true);
    await supabase
      .from('categories')
      .insert([categoryData])
      .select()
      .then((response: PostgrestSingleResponse<Category[]>) => {
        if (response.data) {
          setLoading(false);
          setSuccess(true);
          setError(false);
          window.location.reload();
        }

        if (response.error) {
          setLoading(false);
          console.log(response.error);
          setSuccess(false);
          setError(true);
        }
      });
  };

  return (
    <>
      <Dialog
        title='Create a new category'
        isShown={isShown && !success}
        onConfirm={handleSubmit}
      >
        {!loading && !success && (
          <div className='new-category-container'>
            <TextInput
              placeholder='Name'
              className='form-input'
              value={name}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setName(e.target.value)
              }
            />
            <TextInput
              placeholder='Type'
              className='form-input'
              value={type}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setType(e.target.value)
              }
            />
            <input
              type='date'
              className='form-input'
              value={date}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setDate(e.target.value)
              }
            />
            <input
              type='time'
              className='form-input'
              value={time}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTime(e.target.value)
              }
            />
          </div>
        )}
        {loading && <div>Creating category...</div>}
        {success && <div>New category created</div>}
        {error && <div>Unable to create category. Try again later.</div>}
      </Dialog>
    </>
  );
};

export default NewCategoryV2;
