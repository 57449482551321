import { Button } from 'evergreen-ui';
import './TournamentSchedule.css'; // Import the CSS file for styling
import { useNavigate } from 'react-router-dom';

function TournamentSchedule() {
  let navigate = useNavigate();

  const schedule = [
    {
      category: 'Mixed doubles',
      date: '21-Apr',
      startTime: '5pm',
      checkIn: '4.45pm',
    },
  ];

  return (
    <div className='tournament-schedule'>
      <h2>Tournament Schedule</h2>
      <div className='buttonRow'>
        <Button
          marginRight={16}
          marginBottom={16}
          onClick={() => navigate('/')}
        >
          Back
        </Button>
      </div>
      <div className='event-container'>
        {schedule.map((event, index) => (
          <div key={index} className='event'>
            <h3>{event.category}</h3>
            <p>
              <strong>Date:</strong> {event.date}
            </p>
            <p>
              <strong>Start Time:</strong> {event.startTime}
            </p>
            <p>
              <strong>Check In:</strong> {event.checkIn}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TournamentSchedule;
